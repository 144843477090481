<template>
    <table class="table table-borderless table-sm mb-0" border="0">
        <tbody>
        <tr>
            <td width="25px">
                <div v-if="stepDisabled === true || stepDisabled === 'true'" class="step-number-disabled bg-gray-1">
                    <div class="label">{{ stepNumber }}</div>
                </div>
                <div v-else class="step-number bg-gray-1">
                    <div class="label">{{ stepNumber }}</div>
                </div>
            </td>
            <td class="align-middle" style="color: #212529">
                <span class="me-1" :class="stepDescClass" style="cursor: pointer">{{ stepDesc }}</span> 
                <span v-if="stepTotalSignee !== undefined" class="badge bg-grey">
                    <fa icon="user" /> {{ stepTotalSignee }}
                </span>
                <span v-if="stepTotalDoc !== undefined" class="badge bg-grey">
                    <fa icon="file" /> {{ stepTotalDoc }}
                </span>
            </td>
        </tr>
        </tbody>
    </table>
</template>

<script>
import {ref} from 'vue'

export default {
    name: 'StepWorkflow',
    props: [ 'number', 'desc', 'descClass', 'totalSignee', 'totalDoc', 'disabled' ],
    setup (props) {

        const stepNumber = ref(props.number)
        const stepDesc = ref(props.desc)
        const stepDescClass = ref(props.descClass)
        const stepTotalSignee = ref(props.totalSignee)
        const stepDisabled = ref(props.disabled)

        return { stepNumber, stepDesc, stepDescClass, stepTotalSignee, stepDisabled }
    }
}
</script>

<style>
.step-number {
    position: relative;
    display: flex;
    overflow: hidden;
    width: 25px;
    height: 25px;
    padding: 10px;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    border-radius: 9999px;
    color: #FFFFFF;
    line-height: 1;
    font-weight: 600;
    text-align: center;
    letter-spacing: 0;
    text-transform: uppercase;
    font-size: 11px;
    background-color: var(--bs-primary);
}

.step-number-disabled {
    position: relative;
    display: flex;
    overflow: hidden;
    width: 25px;
    height: 25px;
    padding: 10px;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    border-radius: 9999px;
    color: var(--bs-primary);
    border: 1px solid var(--bs-primary);
    line-height: 1;
    font-weight: 600;
    text-align: center;
    letter-spacing: 0;
    text-transform: uppercase;
    font-size: 11px;
    background-color: --bgcolor-body-color
}
</style>