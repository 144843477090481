import axios from '@/functions/axios-instance'

export default {
    name: "auth",
    async logout() {
        try {
            const res = await axios.get( '/auth/signout')

            if (res.data.status === 1) {
                localStorage.removeItem('sessionId')
                localStorage.removeItem('displayName')
                localStorage.removeItem('avatar')
                localStorage.removeItem('role')
                localStorage.removeItem('userIsVerified')
                localStorage.removeItem('userStatus')
                localStorage.removeItem('userExpired')
                localStorage.removeItem('userLastLogin')
                localStorage.removeItem('projectId')
                localStorage.removeItem('projectName')

                localStorage.removeItem('project')
                localStorage.removeItem('entity')
                localStorage.removeItem('entityId')
                localStorage.removeItem('wlId')
                localStorage.removeItem('package')
                localStorage.removeItem('page')
                localStorage.removeItem('totalPage')
                localStorage.removeItem('permission')
                localStorage.removeItem('structure')
                localStorage.removeItem('team')

                // ProjDoc
                localStorage.removeItem('leftDrawer')
                localStorage.removeItem('leftFloatDrawer')

                // View
                localStorage.removeItem('hasClickViewToolbox')
                localStorage.removeItem('leftViewDrawer')
                localStorage.removeItem('leftViewFloatDrawer')

                localStorage.removeItem('signatoryCtrl')
                localStorage.removeItem('signatoryDoc')
                localStorage.removeItem('signatorySignee')
                localStorage.removeItem('signatoryDbBoxes')


                return ['1', 'success']

            } else {
                return ['0', res.data.message]
            }

        } catch (err) {
            // Handle Error Here
            // console.info('- - - Logout', 'SessionId: ',localStorage.getItem('sessionId'), 'Error: ', err)
            return ['0', err]
        }
    },
    clearStorage() {
        // keep userId for login cache purpose
        localStorage.removeItem('sessionId')
        localStorage.removeItem('displayName')
        localStorage.removeItem('avatar')
        localStorage.removeItem('role')
        localStorage.removeItem('userIsVerified')
        localStorage.removeItem('userStatus')
        localStorage.removeItem('userExpired')
        localStorage.removeItem('userLastLogin')
        localStorage.removeItem('projectId')
        localStorage.removeItem('projectName')

        // ProjDoc
        localStorage.removeItem('leftDrawer')
        localStorage.removeItem('leftFloatDrawer')

        // View
        localStorage.removeItem('hasClickViewToolbox')
        localStorage.removeItem('leftViewDrawer')
        localStorage.removeItem('leftViewFloatDrawer')

        // Signatory
        localStorage.removeItem('signatoryCtrl')
        localStorage.removeItem('signatoryDoc')
        localStorage.removeItem('signatorySignee')
        localStorage.removeItem('signatoryDbBoxes')

    }
}
